<div class="flex-container">
    <div class="flex-items">
        <div class="fab-container-four">
            <div class="header">Status:</div>
            <div class="fabdata" [style.color]="currentOrder.Index === 0 ? 'red':'#1bcc1b'"
                style="font-weight: 600 !important; text-transform: uppercase;">
                {{currentOrder.Status}}</div>
            <div class="header">Quantity:</div>
            <div class="fabdata">{{order.Quantity}}</div>
        </div>
    </div>
    <div class="flex-items">
        <div style="margin-top: 25px;" class="order-progress-full">
            <div class="flex-container-progress">
                <div>
                    <nz-steps [nzCurrent]="currentOrder.Index" [nzProgressDot]="progressTemplate">
                        <ng-container *ngFor="let odr of orderProgressList">
                            <nz-step *ngIf="odr.Index === 0 && currentOrder.Index === 0" [nzTitle]="odr.Progress"
                                nzStatus="error" [nzDescription]="odr.Description | date:'MM-dd-yy'">
                            </nz-step>
                            <nz-step *ngIf="odr.Index === 0 && currentOrder.Index !== 0" [nzTitle]="odr.Progress"
                                [nzDescription]="odr.Description | date:'MM-dd-yy'">
                            </nz-step>
                            <nz-step *ngIf="odr.Index !== 0" [nzTitle]="odr.Progress"
                                [nzDescription]="odr.Description | date:'MM-dd-yy'">
                            </nz-step>
                        </ng-container>
                    </nz-steps>
                    <ng-template #progressTemplate let-dot let-status="status" let-index="index">
                        <span style="margin-left: -100%;">
                            <!-- nz-popover nzPopoverContent="steps {{ index }} status: {{ status }}" -->
                            <ng-template [ngTemplateOutlet]="dot"></ng-template>
                        </span>
                    </ng-template>
                </div>
                <div style="margin-top: -10px;">
                    <button bps-button bpsType="variation-14" bps-popover
                        [bpsPopoverPlacement]="'left'" [bpsPopoverType]="'variation_3'"
                        [bpsPopoverContent]="applyContent9">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.002" height="14.995" viewBox="0 0 14.002 14.995">
                            <path data-name="Subtraction 82"
                                d="M-22490-15425h-14v-7h.012l1.006-7.986h1.982l.852 6.676 5.332-3.615v3.265l4.818-3.265v11.925zm-5-6v2h3v-2zm-6-.006v2h5v-2z"
                                transform="translate(22504.002 15439.998)" style="fill:#fff" />
                        </svg>
                    </button>
                </div>
                <div>
                    <div class="flex-container"
                        style="float: right; align-items: center; display: flex; margin-top: -16px; line-height: 0; margin-left: 45px;">
                        <div class="flex-items">
                            <img alt="" src="/assets/Images/sps/truckload/sps_static_smallicon_truckload_color.png"
                                style="width: 120px"/>
                            <!-- <img alt="" src="/assets/Images/sps/srs/sps_static_icon_sharedtruckload_color.svg"
                                style="width: 100px" *ngIf="shippingMethod === 'STL'" />
                            <img alt="" src="/assets/Images/sps/srs/sps_static_icon_Truckload_color.svg"
                                style="width: 100px" *ngIf="shippingMethod === 'TL'" /> -->
                        </div>
                        <div class="flex-items" style="font-size: 12px;">
                            Estimate Time Arrival
                        </div>
                        <div class="flex-items" style="font-size: 12px; color: #9c9c9c; margin-top: 2px;">
                            12 Weeks
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ng-template #applyContent9>
    <div class="popover-variation3" style="width: 340px; background-color: #262626;">
        <div class="fab-container" *ngIf="order.unifiedModel.ModelInput.FrameSystem.SystemType === 'AWS 75.SI+'">
            <div class="header">Fabricator:</div>
            <div class="fabdata">{{dealerInfo.AWSFabricator.Name}}</div>
            <div class="header">Location:</div>
            <div class="fabdata" style="color: #00a2d1;">{{dealerInfo.AWSFabricator.Line1}},
                {{dealerInfo.AWSFabricator.Line2}},{{dealerInfo.AWSFabricator.State}}
                {{dealerInfo.AWSFabricator.City}}, {{dealerInfo.AWSFabricator.PostalCode}}
            </div>
            <div style="margin-top: 10px;" class="header">Contact: </div>
            <div style="margin-top: 10px;" class="fabdata">{{dealerInfo.AWSFabricator.PrimaryContactName}}</div>
            <div class="header">Email: </div>
            <div class="fabdata"><a href="mailto: {{dealerInfo.AWSFabricator.PrimaryContactEmail}}">
                    {{dealerInfo.AWSFabricator.PrimaryContactEmail}}</a>
            </div>
            <div class="header">Phone: </div>
            <div class="fabdata">{{dealerInfo.AWSFabricator.PrimaryContactPhone}} </div>
        </div>
        <div class="fab-container" *ngIf="order.unifiedModel.ModelInput.FrameSystem.SystemType === 'ADS 75'">
            <div class="header">Fabricator:</div>
            <div class="fabdata">{{dealerInfo.ADSFabricator.Name}}</div>
            <div class="header">Location:</div>
            <div class="fabdata" style="color: #00a2d1;">{{dealerInfo.ADSFabricator.Line1}},
                {{dealerInfo.ADSFabricator.Line2}},{{dealerInfo.ADSFabricator.State}}
                {{dealerInfo.ADSFabricator.City}}, {{dealerInfo.ADSFabricator.PostalCode}}
            </div>
            <div class="header">Contact: </div>
            <div class="fabdata">{{dealerInfo.ADSFabricator.PrimaryContactName}}</div>
            <div class="header">Email: </div>
            <div class="fabdata"><a href="mailto: {{dealerInfo.ADSFabricator.PrimaryContactEmail}}">
                    {{dealerInfo.ADSFabricator.PrimaryContactEmail}}</a>
            </div>
            <div class="header">Phone: </div>
            <div class="fabdata">{{dealerInfo.ADSFabricator.PrimaryContactPhone}} </div>
        </div>
        <div class="fab-container" *ngIf="order.unifiedModel.ModelInput.FrameSystem.SystemType === 'ASE 60'">
            <div class="header">Fabricator:</div>
            <div class="fabdata">{{dealerInfo.ASSFabricator.Name}}</div>
            <div class="header">Location:</div>
            <div class="fabdata" style="color: #00a2d1;">{{dealerInfo.ASSFabricator.Line1}},
                {{dealerInfo.ASSFabricator.Line2}},{{dealerInfo.ASSFabricator.State}}
                {{dealerInfo.ASSFabricator.City}}, {{dealerInfo.ASSFabricator.PostalCode}}
            </div>
            <div class="header">Contact: </div>
            <div class="fabdata">{{dealerInfo.ASSFabricator.PrimaryContactName}}</div>
            <div class="header">Email: </div>
            <div class="fabdata"><a href="mailto: {{dealerInfo.ASSFabricator.PrimaryContactEmail}}">
                    {{dealerInfo.ASSFabricator.PrimaryContactEmail}}</a>
            </div>
            <div class="header">Phone: </div>
            <div class="fabdata">{{dealerInfo.ASSFabricator.PrimaryContactPhone}} </div>
        </div>
    </div>
</ng-template>