<div style="margin-left:15px; margin-right: 15px; margin-top:23px;">
  <div style="margin-left:20px;display:flex;">
    <div class="fontUniversforSchueco-530" style="text-transform: uppercase;margin-top:auto; margin-bottom:auto;">
      {{'report.project-name' | translate }}
      <!-- Project Name -->
    </div>
    <div class="RectangleName">
      <div class="fontUniversforSchueco-530"
        style="height: 16px;margin-top:5.5px;margin-bottom:4.8px;margin-left:14.5px;">{{projectName}}</div>
    </div>
  </div>

  <div class="lineSeparator" style="margin-top: 23px;"></div>

  <!--#region new code-->
  <div class='report-table-demo'>
    <bps-table #bpsExampleTable [data]="listOfDisplayData" [scroll]="{y: 'calc(100vh - 265px)', x: '455px' }"
      [showPagination]="false" [moreMenu]="moreTemplate" [frontPagination]="false" [loading]="isOperating"
      (onclickRow)="log($event)" (selectionChange)="onRowSelected($event)" class="bps-table-example"
      (moreBtnClicked)="log($event)" [inlineEdit]="true" (onedit)="onProblemNameEdit($event)"
      [(config)]="configurationCustomGrid">
    </bps-table>

    <ng-template #noteTemplate let-index="index" let-disabled="disabled" let-disabledNote="disabledNote" let-id="id">
      <div style="text-align: center" (click)="showNotesModal($event,id)">
        <ng-container *ngIf="!disabled && !disabledNote">
          <div bps-tooltip [bpsTooltipType]="'variation_10'"
            [bpsTooltipTitle]="!disabled && !disabledNote? '&nbsp;&nbsp;&nbsp;&nbsp;'+noteAdded+'&nbsp;&nbsp;&nbsp;&nbsp;': null"
            bpsTooltipPlacement="left">
            <img src="/assets/Images/icons/sps_note_icon_report_activated.svg" />
          </div>
        </ng-container>
        <ng-container *ngIf="!disabled && disabledNote">
          <!-- <a  (click)="showNotesModal()"> -->
          <img src="/assets/Images/icons/sps_note_icon_report_enabled.svg" />
          <!-- </a> -->
        </ng-container>
        <ng-container *ngIf="disabled && disabledNote">

          <img src="/assets/Images/icons/sps_note_icon_report_disabled.svg" />

        </ng-container>
      </div>
    </ng-template>

    <ng-template #columnTemplate let-disabled="disabled" let-disabledA="disabledA" let-disabledB="disabledB"
      let-disabledC="disabledC">
      <div style="text-align: center">
        <label bps-checkbox style="margin-left: 6px; margin-right: 10px" bpsType="variation3"
          (ngModelChange)="checkAllA($event)" [bpsDisabled]="disabledA" [(ngModel)]="isAllDisplayDataCheckedA">
          <ng-container *ngIf="isAllDisplayDataCheckedA && !disabledA">
            <img src="/assets/bps-icons/sps_acoustic_icon_report_selected.svg" />
          </ng-container>
          <ng-container *ngIf="!isAllDisplayDataCheckedA && !disabledA">
            <img src="/assets/bps-icons/sps_acoustic_icon_report_enabled.svg" />
          </ng-container>
          <ng-container *ngIf="disabledA">
            <img src="/assets/bps-icons/sps_acoustic_icon_report_disable.svg" />
          </ng-container>
        </label>

        <label bps-checkbox style="margin-right: 10px" bpsType="variation3" (ngModelChange)="checkAllB($event)"
          [bpsDisabled]="disabledB" [(ngModel)]="isAllDisplayDataCheckedB">
          <ng-container *ngIf="isAllDisplayDataCheckedB && !disabledB">
            <img src="/assets/bps-icons/sps_structural_icon_report_selected.svg" />
          </ng-container>
          <ng-container *ngIf="!isAllDisplayDataCheckedB && !disabledB">
            <img src="/assets/bps-icons/sps_structural_icon_report_enabled.svg" />
          </ng-container>
          <ng-container *ngIf="disabledB">
            <img src="/assets/bps-icons/sps_structural_icon_report_disable.svg" />
          </ng-container>
        </label>

        <label bps-checkbox style="margin-right: 10px" bpsType="variation3" (ngModelChange)="checkAllC($event)"
          [bpsDisabled]="disabledC" [(ngModel)]="isAllDisplayDataCheckedC">
          <ng-container *ngIf="isAllDisplayDataCheckedC && !disabledC">
            <img src="/assets/bps-icons/sps_thermal_icon_report_selected.svg" />
          </ng-container>
          <ng-container *ngIf="!isAllDisplayDataCheckedC && !disabledC">
            <img src="/assets/bps-icons/sps_thermal_icon_report_enabled.svg" />
          </ng-container>
          <ng-container *ngIf="disabledC">
            <img src="/assets/bps-icons/sps_thermal_icon_report_disable.svg" />
          </ng-container>
        </label>
        <!-- <label bps-checkbox bpsType="variation3" [ngModel]="false" bpsDisabled>
          <img src="/assets/bps-icons/sps_thermal_icon_report_disable.svg" />
        </label> -->
      </div>
    </ng-template>

    <ng-template #cellTemplate let-id="id" let-disabled="disabled" let-disabled1="disabled1" let-disabled2="disabled2"
      let-disabled3="disabled3">
      <div style="text-align: center" bps-tooltip [bpsTooltipType]="'variation_10'"
        [bpsTooltipTitle]="disabled? '&nbsp;&nbsp;&nbsp;&nbsp;'+notComputedText+'&nbsp;&nbsp;&nbsp;&nbsp;': null"
        bpsTooltipPlacement="left">
        <label bps-checkbox bpsType="variation3" style="margin-left: 3px; margin-right: 10px" [bpsDisabled]="disabled1"
          (ngModelChange)="refreshStatusA($event, id)" [ngModel]="mapOfCheckedIdA[id]">
          <ng-container *ngIf="mapOfCheckedIdA[id] && !disabled1">
            <img src="/assets/bps-icons/sps_acoustic_icon_report_selected.svg" />
          </ng-container>
          <ng-container *ngIf="!mapOfCheckedIdA[id] && !disabled1">
            <img src="/assets/bps-icons/sps_acoustic_icon_report_enabled.svg" />
          </ng-container>
          <ng-container *ngIf="disabled1">
            <img src="/assets/bps-icons/sps_acoustic_icon_report_disable.svg" />
          </ng-container>
        </label>

        <label bps-checkbox bpsType="variation3" style="margin-right: 10px" (ngModelChange)="refreshStatusB($event, id)"
          [bpsDisabled]="disabled2" [ngModel]="mapOfCheckedIdB[id]">
          <ng-container *ngIf="mapOfCheckedIdB[id] && !disabled2">
            <img src="/assets/bps-icons/sps_structural_icon_report_selected.svg" />
          </ng-container>
          <ng-container *ngIf="!mapOfCheckedIdB[id] && !disabled2">
            <img src="/assets/bps-icons/sps_structural_icon_report_enabled.svg" />
          </ng-container>
          <ng-container *ngIf="disabled2">
            <img src="/assets/bps-icons/sps_structural_icon_report_disable.svg" />
          </ng-container>
        </label>

        <label bps-checkbox bpsType="variation3" style="margin-right: 10px" (ngModelChange)="refreshStatusC($event, id)"
          [bpsDisabled]="disabled3" [ngModel]="mapOfCheckedIdC[id]">
          <ng-container *ngIf="mapOfCheckedIdC[id] && !disabled3">
            <img src="/assets/bps-icons/sps_thermal_icon_report_selected.svg" />
          </ng-container>
          <ng-container *ngIf="!mapOfCheckedIdC[id] && !disabled3">
            <img src="/assets/bps-icons/sps_thermal_icon_report_enabled.svg" />
          </ng-container>
          <ng-container *ngIf="disabled3">
            <img src="/assets/bps-icons/sps_thermal_icon_report_disable.svg" />
          </ng-container>
        </label>
        <!-- <label bps-checkbox bpsType="variation3" [ngModel]="false" bpsDisabled>
          <img src="/assets/bps-icons/sps_thermal_icon_report_disable.svg" />
        </label> -->
      </div>
    </ng-template>

    <ng-template #moreTemplate let-index="index" let-disabled="disabled" let-id="id">
      <div style="text-align: center">
        <ng-container *ngIf="!disabled">
          <a bps-dropdown [bpsDropdownMenu]="menu" bpsTrigger="click" (click)="onMoreOptionClick(id)"
            [bpsPlacement]="'rightTop'">
            <img src="/assets/bps-icons/sps_dots_icon_report_white.svg" />
          </a>
        </ng-container>
        <ng-container *ngIf="disabled">
          <img src="/assets/bps-icons/sps_dots_icon_report_grey.svg" />
        </ng-container>
      </div>
    </ng-template>
    <bps-dropdown-menu #menu="bpsDropdownMenu">
      <ul nz-menu class="report-more-overflow-menu">
        <li nz-menu-item class="bps-menu-item" (click)="onSummaryReportClick()"><span
            class="report-summary-report"></span><span class="report-titles">{{'report.summary-report' | translate
            }}</span></li>
        <!-- Summary Report -->
        <li nz-menu-item class="bps-menu-item" nzDisabled style="color: #262626; cursor: not-allowed;"><span
            class="report-bookmarks"></span><span class="report-titles">{{'report.bookmarks' | translate }}</span></li>
        <!-- Bookmarks -->
      </ul>
    </bps-dropdown-menu>



    <bps-modal [(bpsVisible)]="isNotesModalVisible" [bpsFooter]="null" [bpsWidth]="400" (bpsOnCancel)="handleCancel()"
      bpsTitle="{{'report.project-notes' | translate }}">
      <div><span class="product-field">{{'report.comments' | translate | uppercase }}</span></div>
      <!-- COMMENTS -->
      <form bps-form [formGroup]="validateForm" (ngSubmit)="submitForm(validateForm.value)">
        <bps-form-item>
          <bps-form-control>
            <textarea class="bps-cmacs-custom-scrollbar" rows="12" bps-input [(ngModel)]="comment"
              formControlName="comment"></textarea>
          </bps-form-control>
        </bps-form-item>

        <div style="text-align: center; margin-top: 25px">
          <button style="width: 165px" bps-button bpsType="variation-1" [disabled]="!isValid()">{{'report.add-notes' |
            translate }}</button>
          <!-- Add notes -->
        </div>
      </form>
    </bps-modal>

  </div>
  <!--#endregion new code-->


  <!-- <div style="width: 100%; margin-top:25px;margin-bottom:25px;">
    <div> 
      <button style="display: table; margin: 0 auto; width: 165px;" [disabled]="!enableDownload" bps-button bpsType="variation-1" (click)="onDownloadClick()">{{'report.download' | translate }}</button>
    </div>
  </div> -->
  <div style="width: 100%; margin-top:25px;margin-bottom:25px;display:flex;">
    <div>
      <button style="display: table; margin: 0 auto; width: 165px;margin-left:155px;" [disabled]="!enableDownload"
        bps-button bpsType="variation-1" (click)="onDownloadClick()">{{'report.download' | translate }}</button>
    </div>
    <!-- Download -->
    <div class="iconI" value="terrain-category" style="height:16px;" bps-popover [bpsPopoverPlacement]="'right'"
      [bpsPopoverType]="'variation_4'" [bpsPopoverContent]="tooltip1">
      <img src="/assets/Images/icons/sps_information_icon_blue.svg">
    </div>
    <ng-template #tooltip1>
      <div class="popover-variation4" style="display:flex;">
        <div>
          {{'report.select-one-or-multiple-of-the-following' | translate }} &nbsp;
          <!-- Select one or multiple of the following &nbsp; -->
          <!-- Guidance -->
        </div>
        <div>
          <img src="/assets/bps-icons/sps_acoustic_icon_report_enabled.svg" />
        </div>
        <div style="margin-left: 7px;">
          <img src="/assets/bps-icons/sps_structural_icon_report_enabled.svg" />
        </div>
        <div style="margin-left: 7px;">
          <img src="/assets/bps-icons/sps_thermal_icon_report_enabled.svg" />
        </div>
        <div>
          &nbsp; {{'report.to-download-reports' | translate }}.
          <!--&nbsp; to download reports.-->
        </div>

      </div>


    </ng-template>
  </div>
</div>