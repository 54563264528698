export enum PanelsModule {
  DoorLeafActive,
  DoorLeafPassive,
  FrameCombination,
  HandleColor,
  HingeType,
  InsideHandle,
  IntermediateMullion,
  IntermediateMullionFacade,
  IntermediateTransom,
  IntermediateTransomFacade,
  Mullion,
  MullionFacade,
  OuterFrame,
  OuterFrameFacade,
  OutsideHandle,
  ProfileColor,
  Reinforcement,
  ReinforcementFacade,
  SillProfileBottom,
  SillProfileFixed,
  SpacerType,
  Transom,
  TransomFacade,
  UDCBottomFraming,
  UDCFraming,
  UDCFramingFacade,
  VentFrame,
  VentFrameFacade,
  WindLoad,

  GlassPanel,
  Structural,
  DoorOperableType,
  ThreeDViewer,

  System,
  SystemType,
  SystemTypeFacade,

  CloseFraming,
  CloseSpacerType,
  CloseFrameCombination,

  OpenCloseGlassPanel,

  ClearStructural,
  PressureValue,
  Load,
  BottomOuterFrameSliding,
  InterlockSliding,
  ReinforcementSliding,
  DoubleVentSliding,
  StrucuralSliding
}