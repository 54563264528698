<nz-header>
    <div id="nav-left">
        <div id="logo-container" (click)="onNavigateToHome()">
            <img alt="" src="/assets/Images/sps/sps_spsolver_white_logo.svg" *appCheckPermissions="feature.BPSLogo">
            <img alt="" src="/assets/Images/sps_logo_icon_SRS_white.png" *appCheckPermissions="feature.SRSLogo">
        </div>
        <!-- <div (click)="logOut()">
            <img alt="" src="/assets/Images/logout-200px.png" width="20">
        </div> -->
        <div *ngIf="isNavBarButtonAndTitleVisible" (click)="onNavigateToConfigure()" class="nav-button"
            [ngClass]="{'active':isConfigureClicked}">
            <div class="nav-button-text">
                <a>{{'nav-layout.configure' | translate | uppercase}}</a>
                <!-- CONFIGURE -->
            </div>
        </div>
    </div>
    <div id="nav-right">
        <ng-container *appCheckPermissions="feature.Result && feature.Report">
            <div id="nav-right-a" *ngIf="isNavBarButtonAndTitleVisible && isComputeClicked else disabledButtons">
                <div *appCheckPermissions="feature.Result" class="nav-button" (click)="onNavigateToResult()" [ngClass]="{'active':isResultClicked}">
                    <div class="nav-button-text">
                        <a>{{'nav-layout.result' | translate | uppercase}}</a>
                        <!-- RESULT -->
                    </div>
                </div>
                <div *appCheckPermissions="feature.Report" class="nav-button" (click)="onNavigateToReport()" [ngClass]="{'active':isReportClicked}">
                    <div class="nav-button-text">
                        <a>{{'nav-layout.report' | translate | uppercase}}</a>
                        <!-- REPORT -->
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *appCheckPermissions="feature.MyOrder">
            <div id="nav-right-a" *ngIf="isNavBarButtonAndTitleVisible else disabledButtons">
                <div class="nav-button" (click)="onNavigateToOrders()" [ngClass]="{'active': MyOrderSelected}">
                    <div class="nav-button-text">
                        <a>MY ORDERS</a>
                        <!-- TODO: Translate - my order -->
                    </div>
                </div>
                <!-- <img src="/assets/Images/sps/srs/sps_icon_lock.svg" alt="" /> -->
            </div>
        </ng-container>
        <ng-template #disabledButtons>
            <div id="nav-right-a">
                <div class="nav-button-disabled">
                    <div class="nav-button-text disabled" *ngIf="isNavBarButtonAndTitleVisible">
                        <p>{{'nav-layout.result' | translate | uppercase}}</p>
                        <!-- RESULT -->
                    </div>
                </div>
                <div class="nav-button-disabled">
                    <div class="nav-button-text disabled" *ngIf="isNavBarButtonAndTitleVisible">
                        <p>{{'nav-layout.report' | translate | uppercase}}</p>
                        <!-- REPORT -->
                    </div>
                </div>
            </div>
        </ng-template>
        <div id="nav-right-b">
            <div>
                <ng-container>
                    <p *ngIf="isNavBarButtonAndTitleVisible">
                        <span id="project-name">{{ configureService.projectName }}</span> - 
                        <span id="project-location">{{ configureService.projectLocation }}</span>
                    </p>
                </ng-container>
                <!-- <ng-container *ngIf="applicationType === 'SRS'">
                    <p *ngIf="isNavBarButtonAndTitleVisible">
                        <span id="project-name">{{ configureService.projectName }}</span>,
                        <span id="project-location">{{ configureService.projectLocation }}</span>
                    </p>
                </ng-container> -->
            </div>
        </div>
        <div id="nav-right-c">
            <!-- added image for feedback  -->
            <img alt="" src="/assets/Images/sps/sps_square_icon_addnotes_white.svg" bps-tooltip *ngIf="applicationType==='BPS'"
            [bpsTooltipType]="'variation_11'" bpsTooltipTitle="{{'nav-layout.feedback' | translate }}" 
            bpsTooltipPlacement="bottom" style="cursor: pointer; visibility: visible; margin-right: 19px;"  class="bps-save-button" (click)="onOpenFeedback()"/>
            
            <!-- Language Selection Part -->
            <div class="icondropdownNavLayout" *ngIf="!isConfigureClicked && !isResultClicked && !isReportClicked && applicationType==='BPS'">
                <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menuLanguage" nzPlacement="bottomCenter" 
                    class="languageDropdown">
                    <img alt="" class="languageImg" [attr.alt]="language === 'en-US' ? 'American Flag':'Deutsche Flag'" 
                    [attr.src]="language === 'en-US' ? '/assets/Images/flags/UK.png':'/assets/Images/flags/DE.png'">
                </button>
                <nz-dropdown-menu #menuLanguage="nzDropdownMenu">
                    <ul nz-menu class="dropdown-menu" role="menu">
                        <li nz-menu-item id="aChangeLanguageDE" (click)="onChangeLanguage('de-DE')"><img class="languageImg" alt="Deutsche Flag" src="/assets/Images/flags/DE.png"></li>
                        <li nz-menu-item id="aChangeLanguageEN" (click)="onChangeLanguage('en-US')"><img class="languageImg" alt="American Flag" src="/assets/Images/flags/UK.png"></li>
                    </ul>
                </nz-dropdown-menu>
            </div>

            <div id="bps-download-button" class="bps-download-button" (click)="onDownloadButtonClick()"
                *ngIf="showDownload && isConfigureClicked"></div>
            <div id="bps-save-button" class="bps-save-button"
                [ngStyle]="{'background-image':showBlueSaveButton?'url(/assets/Images/sps/sps_square_icon_save_blue.svg)':'url(/assets/Images/sps/sps_square_icon_save_white.svg)'}"
                (click)="onSaveButtonClick()" style="cursor: pointer; visibility: visible;" *ngIf="isConfigureClicked">
            </div>
            <div class=" user-name welcome">
                {{'nav-layout.welcome-back' | translate }}<span> {{ userFullName }}</span>
                <!-- Welcome back -->
            </div>
            <div class="user-icon-container">
                <i nz-icon nzType="user" nzTheme="outline"></i>
            </div>
            <!-- <div class="login-arrow-down-container">
                <img alt="" class="intro-page-heading-spacing-1" src="/assets/Images/sps/sps_arrowdown_icon_expand_darkgrey.svg">
            </div> -->
            <!-- <a class="login-arrow-down-container" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
                <img alt="" class="intro-page-heading-spacing-1" src="/assets/Images/sps/sps_arrowdown_icon_expand_darkgrey.svg">
            </a> -->
            <button bps-button bpsType="variation-16" class="login-arrow-down-container" bps-dropdown
                [bpsDropdownMenu]="menu2" bpsTrigger="click" [bpsPlacement]="'bottomRight'">
                <img alt="" class="intro-page-heading-spacing-1"
                    src="/assets/Images/sps/sps_arrowdown_icon_expand_darkgrey.svg">
            </button>
            <bps-dropdown-menu #menu2="bpsDropdownMenu">
                <ul nz-menu>
                    <li nz-menu-item class="bps-menu-item bps-menu-item-login" nzDisabled><span
                            class="login-settings"></span><span class="login-titles">{{'nav-layout.settings' | translate
                            }}</span></li>
                    <li nz-menu-item class="bps-menu-item bps-menu-item-login" nzDisabled><span
                            class="login-shuco"></span><span class="login-titles-shuco">Schüco</span></li>
                    <li nz-menu-item (click)="logOut()" class="bps-menu-item bps-menu-item-logout bps-menu-item-login">
                        <span class="login-logout"></span><span class="login-titles">{{'nav-layout.log-out' | translate
                            }}</span>
                    </li>
                </ul>
            </bps-dropdown-menu>

            <!-- <a *ngIf="1 == 2" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" class="login-arrow-down-container">
                <img alt="" class="intro-page-heading-spacing-1" src="/assets/Images/sps/sps_arrowdown_icon_expand_darkgrey.svg">
            </a> -->
            <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu class="login-menu" role="menu">
                    <li nz-menu-item (click)="onSelectLoginOption('option1')" nzDisabled>{{'nav-layout.settings' |
                        translate }}</li>
                    <!-- Settings -->
                    <li nz-menu-item (click)="onSelectLoginOption('option1')" nzDisabled>Schüco</li>
                    <li nz-menu-item (click)="onSelectLoginOption('option1')" nzDisabled>{{'nav-layout.help-and-support'
                        | translate }}</li>
                    <!-- Help & Support -->
                    <li nz-menu-item (click)="onSelectLoginOption('option1')" nzDisabled>{{'nav-layout.contact-us' |
                        translate }}</li>
                    <!-- Contact Us -->
                    <li nz-menu-item (click)="onSelectLoginOption('option1')" nzDisabled>{{'nav-layout.legal' |
                        translate }}</li>
                    <!-- Legal -->
                    <li nz-menu-item (click)="logOut()">{{'nav-layout.log-out' | translate }}</li>
                    <!-- Log out -->
                </ul>
            </nz-dropdown-menu>
        </div>
    </div>
</nz-header>

<button bps-button bpsType="variation-18" *ngIf="showCrossButton && previousRoute" (click)="onComePreviousPage()"
    class="cross-button">
    <svg xmlns="http://www.w3.org/2000/svg" width="15.392" height="14.054" viewBox="0 0 15.392 14.054">
        <defs>
            <style>
                .prefix__cls-1 {
                    fill: none;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-width: 2px
                }
            </style>
        </defs>
        <g id="prefix__sps_round_icon_back_white" transform="translate(-2533.021 -328.325)">
            <g id="prefix__sps_round_icon_rightpanelopened_white" transform="translate(1349.387 261.965)">
                <path id="prefix__Path_287" d="M1878.866 408.4l-5.613-5.613 5.613-5.613" class="prefix__cls-1"
                    data-name="Path 287" transform="rotate(180 1535.433 238.09)" />
            </g>
            <path id="prefix__Path_1295" d="M-9320.857-21989.648H-9333" class="prefix__cls-1" data-name="Path 1295"
                transform="translate(11867.02 22325)" />
        </g>
    </svg>
</button>
