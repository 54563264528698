<div class="faq-container">
<div class="leftContent"></div>
<div class="rightContent">
    <h3 class="faq-heading"> FAQ </h3>
     <button bps-button bpsType="variation-18"  
    class="cross-button" *ngIf="showBackButton" (click)="navigateToHome()">
    <svg xmlns="http://www.w3.org/2000/svg" width="8.441" height="14.054" viewBox="0 0 8.441 14.054">
        <g id="prefix__sps_round_icon_rightpanelclosed_white" transform="rotate(180 582.514 40.207)">
          <path id="prefix__Path_291" d="M1878.866 408.4l-5.613-5.613 5.613-5.613" data-name="Path 291" transform="rotate(180 1518.433 238.09)" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-width:2px"/>
        </g>
      </svg>
</button> 
    
<div class ="faq-content">
    <p class ="page-heading">This page is under development </p> 
</div>


</div>
</div>
