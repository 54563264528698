export let addressStructure = {//long_name, short_name
    name: 'long_name',
    premise: 'long_name',
    point_of_interest: 'long_name',
    lat: 'long_name',
    lng: 'long_name',
    location: 'long_name',
    location_type: 'long_name',
    formatted_address: 'long_name',
    bounds: 'long_name',
    viewport: 'long_name',
    route: 'long_name',
    street_number: 'long_name',
    postal_code: 'long_name',
    locality: 'long_name',
    sublocality_level_2: 'long_name',
    sublocality_level_1: 'long_name',
    country: 'long_name',
    country_short: 'long_name',
    administrative_area_level_1: 'long_name',
    administrative_area_level_2: 'long_name',
    place_id: 'long_name',
    id: 'long_name',
    reference: 'long_name',
    url: 'long_name',
    website: 'long_name'
  };
  